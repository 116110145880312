import { _Client } from "@/api-client";
import {
    CertificationRecordsFollowupActionEnum,
    CertificationRecordsHistoryDetail,
    CertificationRecordsHistoryQuery,
    CertificationRecordsHistoryRep,
    ExaminerCertificationStatusEnum,
    SystemStatusEnum
} from "@/api-client/client";
import { defineComponent,onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { LeftOutlined} from '@ant-design/icons-vue';
import FooterBar from '@/components/FooterBar/index.vue'

export default defineComponent({
    components:{
        LeftOutlined,
        FooterBar
    },
    setup(){
        const {currentRoute,push,go} = useRouter()
        const columns= [
            {
                title: 'ID',
                key: 'ID',
                slots: { customRender: 'ID' },
                width:100
            },
            {
                title: 'Module',
                dataIndex: 'module',
                key: 'module',
            },
            {
                title:"Set",
                dataIndex:'certificationSet'
            },
            {
                title:"First Attempt Result",
                slots: { customRender: 'firstAttempt' }
            },
            {
                title:"First Attempt Result Date",
                dataIndex:'firstAttemptDate'
            },
            {
                title:"Second Attempt Result",
                slots: { customRender: 'secondAttempt' }
            },
            {
                title:"Second Attempt Result Date",
                dataIndex:'secondAttemptDate'
            },
            {
                title:"Certification Date",
                dataIndex:'certificationDate'
            }
        ]
        const certificationSystemStatus = SystemStatusEnum;
        const examinerCertificationStatusEnum=ExaminerCertificationStatusEnum;
        const id = <string>currentRoute.value.params.id;
        const detailHistory:CertificationRecordsHistoryRep = ref(new CertificationRecordsHistoryRep().toJSON())

        async function getDetail() {
            detailHistory.value = await _Client.certificationRecordClient.historyQuery(new CertificationRecordsHistoryQuery({certificationId:id}))
            if (detailHistory.value.certificationSystemStatus!==SystemStatusEnum.Valid){
                let historyDetail = <CertificationRecordsHistoryDetail[]>detailHistory.value.certificationRecordsHistoryDetail;
                let item = historyDetail.find(x=>x.isCanModify)
            }
        }

        const certificationRecordsFollowupActionEnum = CertificationRecordsFollowupActionEnum;
        const saveLoading= ref(false)


        async function back(){
            await push({path:'../examiner-records'})
        }

        onMounted(async ()=>{
            await getDetail();
        })

        return {
            columns,
            certificationSystemStatus,
            examinerCertificationStatusEnum,
            detailHistory,
            back,
            certificationRecordsFollowupActionEnum,
            saveLoading,
        }
    }
})